import SignaturePad from 'signature_pad';
import ExprEval from 'expr-eval';

import { calcFieldsHash, checkSignatures, findFormFieldById, findFormFields, formatDate, getEmailTargets, getExportFilename, isInputFormField, replaceTemplateVarFieldValues, sanitizeFieldId } from '../common/common-helpers';
import { FormData, FormExtendableEdit, FormField, FormFieldCalc, FormFieldExtendable, FormFieldImages, FormFieldInput, FormFieldSelect, FormFieldSignatures, FormFileInfo, FormValue, FromChanges } from '../common/form-types';
import { findFormfieldRootElement, resizeTextarea } from './browser-helpers';
import { PromiseQueue } from './promise-queue';

type HTMLInputOrTextareaElement = HTMLInputElement | HTMLTextAreaElement;
type HTMLInputOrTextareaElements = [ HTMLInputOrTextareaElement, ...Array<HTMLInputOrTextareaElement> ];

type FormFieldWithElements<T extends FormField = FormField> = T & {
  containerElem: HTMLElement;
  inputElems: HTMLInputOrTextareaElements;
};

export class FormEdit {

  private btnPdf: HTMLButtonElement;

  private btnEmail: HTMLButtonElement | null; // this element may not be there if email is not enabled

  private btnEdit: HTMLButtonElement;

  private btnEditDone: HTMLButtonElement;

  private btnArchiveIn: HTMLButtonElement;

  private btnArchiveOut: HTMLButtonElement;

  private btnRevert: HTMLButtonElement;

  private btnSave: HTMLButtonElement;

  private btnDelete: HTMLButtonElement;

  private btnAddSub: HTMLButtonElement;

  private uploadIndicator: HTMLElement;

  private editing: boolean = false;

  private signed: boolean = false;

  private hasChanges: boolean = false;

  private q: PromiseQueue = new PromiseQueue();

  private parser: ExprEval.Parser = new ExprEval.Parser();

  constructor (formData: FormData) {
    this.parser.functions.sum = (...items: number[]) => items.reduce((pv, cv) => pv + cv, 0);

    this.btnPdf = document.getElementById('btn-pdf') as HTMLButtonElement;
    this.btnEmail = document.getElementById('btn-email') as HTMLButtonElement | null;
    this.btnEdit = document.getElementById('btn-edit') as HTMLButtonElement;
    this.btnEditDone = document.getElementById('btn-edit-done') as HTMLButtonElement;
    this.btnArchiveIn = document.getElementById('btn-archive-in') as HTMLButtonElement;
    this.btnArchiveOut = document.getElementById('btn-archive-out') as HTMLButtonElement;
    this.btnRevert = document.getElementById('btn-revert') as HTMLButtonElement;
    this.btnSave = document.getElementById('btn-save') as HTMLButtonElement;
    this.btnDelete = document.getElementById('btn-delete') as HTMLButtonElement;
    this.btnAddSub = document.getElementById('btn-add-sub') as HTMLButtonElement;
    this.uploadIndicator = document.getElementById('upload-indicator') as HTMLElement;

    this.btnPdf.onclick = () => {
      if (formData.checkout?.values) {
        const changedFields = Object.getOwnPropertyNames(formData.checkout.values);
        if (changedFields.length > 0) {
          if (!confirm(`Achtung: Es gibt aktuell ${changedFields.length} nicht gespeicherte ${changedFields.length > 1 ? 'Änderungen' : 'Änderung'}.\n\nDiese Änderungen werden beim PDF-Export nicht berücksichtigt!\n\nSoll der Export dennoch durchgeführt werden?`)) {
            return;
          }
        }
      }

      // get the export filename
      const name = getExportFilename(formData);

      window.open(`${base}forms${formIsArchived ? '/archive/' : '/'}${formData.id}/pdf/${name}.pdf`);
    };

    if (formData.hasSequentialNumber === 'multiple') {
      this.btnAddSub.disabled = false;
      this.btnAddSub.classList.remove('w3-hide');
      this.btnAddSub.onclick = () => {
        if (confirm(`Soll ein zugehöriges Formular mit fortlaufender Endnummer erstellt werden?`)) {
          window.location.assign(`${base}forms/new/${encodeURIComponent(formData.type)}/${encodeURIComponent(formData.sequentialNumber ?? '')}`);
        }
      };
    }

    if (formData.checkout) {
      // form is checked out

      if (formData.checkout.user.id === userData?.id) {
        // form is checked out by the current user
        this.btnEditDone.classList.remove('w3-hide');
        this.btnEditDone.disabled = false;

        this.btnEditDone.onclick = () => {
          const changedFields = Object.getOwnPropertyNames(formData.checkout?.values);
          if (changedFields.length > 0) {
            alert(`Es gibt aktuell ${changedFields.length} nicht gespeicherte ${changedFields.length > 1 ? 'Änderungen' : 'Änderung'}.\n\nDiese Änderungen müssen vor dem Beenden der Bearbeitung erst gespeichert oder verworfen werden!`);
            return;
          }

          window.location.assign(`${base}forms/${formData.id}/checkin`);
        };

        this.btnRevert.classList.remove('w3-hide');
        this.btnRevert.onclick = () => {
          if (!confirm('Alle aktuellen Änderungen wirklich verwerfen?')) return;
          this.q.enqueue(async () => {
            window.location.assign(`${base}forms/${formData.id}/revert`);
          });
        };

        this.btnSave.classList.remove('w3-hide');
        this.btnSave.onclick = () => {
          this.q.enqueue(async () => {
            window.location.assign(`${base}forms/${formData.id}/apply`);
          });
        };

        this.editing = true;

      } else {
        // form is checked out by an other user
        this.btnEdit.disabled = false;
        this.btnEdit.classList.remove('w3-hide');
        this.btnEdit.onclick = () => {
          if (confirm(`Das Formular wird aktuell von ${formData.checkout?.user.name} bearbeitet.\n\nDas Starten der Bearbeitung bricht die Bearbeitung dieses Benutzer ab!\n\nWirklich die Bearbeitung starten?`)) {
            window.location.assign(`${base}forms/${formData.id}/checkout`);
          }
        };

      }

      this.updateChangedState();

    } else if (formEditAllowed) {
      // form is not checked out and editing is allowed
      if (!formIsArchived) {
        this.btnEdit.disabled = false;
        this.btnEdit.classList.remove('w3-hide');
        this.btnEdit.onclick = () => {
          window.location.assign(`${base}forms/${formData.id}/checkout`);
        };
      }

      if (formIsArchived) {
        this.btnArchiveOut.disabled = false;
        this.btnArchiveOut.classList.remove('w3-hide');
        this.btnArchiveOut.onclick = () => {
          if (confirm(`Soll dieses Formular wirklich aus den Archiv geholt werden?`)) {
            window.location.assign(`${base}forms/archive/${formData.id}/archive-out`);
          }
        };
      } else {
        this.btnArchiveIn.disabled = false;
        this.btnArchiveIn.classList.remove('w3-hide');
        this.btnArchiveIn.onclick = () => {
          if (confirm(`Soll dieses Formular wirklich in das Archiv verschoben werden?\n\nArchivierte Formulare werden nicht direkt in der Liste angezeigt und können nicht bearbeitet werden.`)) {
            window.location.assign(`${base}forms/${formData.id}/archive-in`);
          }
        };
      }

      this.btnDelete.disabled = false;
      this.btnDelete.classList.remove('w3-hide');
      this.btnDelete.onclick = () => {
        if (confirm(`Soll dieses Formular wirklich gelöscht werden?\n\nDabei werden alle Daten und Dateien dieses Formulars gelöscht.\nDies kann nicht rückgängig gemacht werden!`)) {
          window.location.assign(`${base}forms${formIsArchived ? '/archive/' : '/'}${formData.id}/delete`);
        }
      };

    }

    // email button only enabled if the form is not checked out, not archived and the current user is allowed to edit
    if (this.btnEmail) {
      if (!formData.email?.to || !!formData.checkout || formIsArchived || !formEditAllowed) {
        // send mail is not possible
        this.btnEmail.disabled = true;
      } else {
        // send mail is possible
        this.btnEmail.disabled = false;

        this.btnEmail.onclick = () => {
          if (!formData.email) return;

          // get to and cc recipients to display them to the user
          const { to, cc } = getEmailTargets(formData.email, userData);

          let recipients: string = to.join(', ');
          if (cc.length > 0) {
            recipients += `\n\nCC:\n${cc.join(', ')}`;
          }

          // ask user for confirmation
          if (confirm(`✉️ Dies sendet eine E-Mail mit dem Formular als PDF-Datei im Anhang.\n\nEmpfänger:\n${recipients}\n\nForfahren?`)) {
            window.location.assign(`${base}forms${formIsArchived ? '/archive/' : '/'}${formData.id}/email`);
          }
        };
      }
    }

    // get all input containers
    let elems = document.querySelectorAll<HTMLElement>('.input-container');

    elems.forEach((elem) => {
      const field = this.getFormFieldFromElement(elem);
      if (!field || !isInputFormField(field)) return;

      // set value from checkout cache if set - always set the value here to display the correct format etc.
      this.setElementValue(field, formData.checkout?.values[field.id]);

      // enable/disabled fields depending on editing state
      for (const inputElem of field.inputElems) {
        this.inputDisable(inputElem, !this.editing, 'notEditing');
      }

      switch (field.type) {
        case 'calc':
          this.setupCalcField(field);

          field.inputElems[0].addEventListener('change', (ev) => this.handleFieldChange(field, field.inputElems[0], ev));
          break;
        case 'images':
          this.updateImagesContainer(field);

          // handle changes on file select
          field.inputElems[0].addEventListener('change', (ev) => this.handleFieldChange(field, field.inputElems[0], ev));

          // handle add sketch button clicks
          field.inputElems[1].addEventListener('click', () => this.openImageEditor(null, field));
          break;

        case 'select':
          // setup the options
          this.setupSelectField(field);

          // use onchange to apply changes after the field loses the focus
          field.inputElems[0].addEventListener('change', (ev) => this.handleFieldChange(field, field.inputElems[0], ev));
          break;

        case 'signatures':
          this.updateSignaturesContainer(field);
          break;

        default:
          for (const inputElem of field.inputElems) {
            // use oninput to apply changes while the user is typing
            if ([ 'text', 'textarea', 'number' ].includes(inputElem.type)) {
              inputElem.addEventListener('input', (ev) => this.handleFieldChange(field, inputElem, ev));
            }

            // use onchange to apply changes after the field loses the focus
            inputElem.addEventListener('change', (ev) => this.handleFieldChange(field, inputElem, ev));
          }
      }
    });

    // handle extendable field buttons
    let buttons = document.querySelectorAll<HTMLButtonElement>('.formfield-extendable-add-btn');
    buttons.forEach((btn) => {
      const fieldId = btn.dataset.fieldId;
      if (!fieldId) return;

      const field = findFormFieldById<FormFieldExtendable>(formData.fields, fieldId);
      if (!field) return;

      this.inputDisable(btn, !this.editing || (typeof field.max === 'number' && field.fields.length >= field.max), 'not-active');

      btn.onclick = async () => {
        if (!confirm(`Soll ein neuer Eintrag für "${field.label}" hinzugefügt werden?`)) {
          return;
        }

        await this.q.enqueue(async () => {
          try {
            const data: FormExtendableEdit = {
              id: fieldId,
              add: true,
            };
            const res = await fetch(`${base}forms/${formData.id}/extendable-edit`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
            });

            const json = await res.json();

            if (json.error) {
              throw new Error(json.error);
            }

            // reload the page to apply the changes
            location.reload();

          } catch (err) {
            console.error(err);
            alert(`Fehler beim Hinzufügen des Eintrages!\n\n${err}`);
          }
        });
      };
    });

    buttons = document.querySelectorAll<HTMLButtonElement>('.formfield-extendable-delete-btn');
    buttons.forEach((btn) => {
      const fieldId = btn.dataset.fieldId;
      const rowIdx = parseInt(btn.dataset.rowIdx as string, 10);
      if (!fieldId || isNaN(rowIdx)) return;

      const field = findFormFieldById<FormFieldExtendable>(formData.fields, fieldId);
      if (!field) return;

      this.inputDisable(btn, !this.editing || field.fields.length <= 1 || (typeof field.min === 'number' && field.fields.length <= field.min), 'not-active');

      btn.onclick = async () => {
        if (!confirm(`Soll der Eintrag ${rowIdx + 1} für "${field.label}" entfernt werden?\n\nDies kann nicht rückgängig gemacht werden.`)) {
          return;
        }

        await this.q.enqueue(async () => {
          try {
            const data: FormExtendableEdit = {
              id: fieldId,
              delete: rowIdx,
            };
            const res = await fetch(`${base}forms/${formData.id}/extendable-edit`, {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
            });

            const json = await res.json();

            if (json.error) {
              throw new Error(json.error);
            }

            // reload the page to apply the changes
            location.reload();

          } catch (err) {
            console.error(err);
            alert(`Fehler beim Entfernen des Eintrages!\n\n${err}`);
          }
        });
      };
    });

    // handle group collapsible buttons
    buttons = document.querySelectorAll<HTMLButtonElement>('.formfield-group-collapse-btn');
    buttons.forEach((btn) => {
      btn.onclick = () => this.handleGroupCollapseButton(btn);
    });

    // check/set collapsed state for all groups
    elems = document.querySelectorAll('.formfield-group');
    elems.forEach((elem) => {
      const fieldId = elem.dataset.id ?? elem.id.replace(/^formfield-group-/, '');
      this.setCollapsed(fieldId);
    });

    // hide fields where value matches the given hideIfValue
    if (!this.editing) {
      const fields = findFormFields<FormFieldInput>(formData.fields, (f) => isInputFormField(f) && typeof f.hideIfValue !== 'undefined');
      for (const field of fields) {
        let strVal: string;
        if (field.type === 'checkboxes') {
          // special case checkboxes - create an array of all sub-checkbox fields
          const values: boolean[] = [];
          for (const f of field.fields) {
            values.push(f.value);
          }
          strVal = JSON.stringify(values);

        } else {
          // other fields
          switch (typeof field.value) {
            case 'string':
            case 'number':
            case 'boolean':
            case 'undefined':
              strVal = `${field.value}`;
              break;
            default:
              strVal = JSON.stringify(field.value);
          }
        }

        if ((Array.isArray(field.hideIfValue) && field.hideIfValue.includes(strVal)) || field.hideIfValue === strVal) {
          // hide the field
          const elem = findFormfieldRootElement(`formfield-container-${field.id}`);
          if (!elem) continue;
          elem.classList.add('hide-because-empty');
        }
      }
    }

    // prevent native form submit and set editing flag on forms
    document.querySelectorAll<HTMLFormElement>('form.form').forEach((f) => {
      f.addEventListener('submit', (ev) => {
        ev.preventDefault();
        return false;
      });
      if (this.editing) {
        f.classList.add('editing');
      } else {
        f.classList.remove('editing');
      }
    });

    this.updateSignedState();

    if (!checkSignatures(formData.fields)) {
      document.getElementById('manipulation-warning')?.classList.remove('w3-hide');
      alert('Warnung: Die Formulardaten wurden nach dem Einfügen von mindestens einer Unterschrift verändert!');
    }
  }

  private getFormFieldFromElement (containerElem: HTMLElement): FormFieldWithElements | undefined {
    const id = containerElem.dataset.id ?? containerElem.id.replace(/^formfield-container-/, '');

    const inputElems = [] as unknown as HTMLInputOrTextareaElements;
    containerElem.querySelectorAll<HTMLInputOrTextareaElement>('.formfield').forEach((elem) => inputElems.push(elem));

    const field = findFormFieldById(formData?.fields, id);

    if (!inputElems[0] || !field) {
      return undefined;
    }


    return {
      ...field,
      inputElems,
      containerElem,
    };
  }

  private async handleFieldChange (field: FormFieldWithElements, inputElem: HTMLInputOrTextareaElement, ev: Event): Promise<void> {
    if (!formData?.checkout || !this.editing) return;

    // make sure checkout.values is an object
    formData.checkout.values ||= {};

    let curVal: FormValue;

    switch (field.type) {
      case 'text':
        curVal = inputElem.value;

        if (typeof field.maxLength === 'number' && curVal.length > field.maxLength) {
          curVal = curVal.slice(0, field.maxLength);
        }

        if (ev.type === 'change' && field.trim !== false) {
          curVal = (curVal as string).trim();
        }

        break;

      case 'number':
      case 'calc':
        curVal = inputElem.value.replace(/[^0-9,.-]/g, '');
        if (ev.type === 'change') {
          curVal = curVal.replace(',', '.');
          if (typeof field.decimals === 'number' && field.decimals > 0) {
            const e = Math.pow(10, field.decimals);
            curVal = Math.round(parseFloat(curVal) * e) / e;
          } else {
            curVal = parseInt(curVal, 10);
          }


          if (field.type === 'number') {
            if (isNaN(curVal)) {
              curVal = null;
            } else {
              if (typeof field.max === 'number' && curVal > field.max) {
                curVal = field.max;
              }
              if (typeof field.min === 'number' && curVal < field.min) {
                curVal = field.min;
              }
            }
          } else {
            if (isNaN(curVal)) {
              curVal = null;
            }
          }
        }

        break;

      case 'date':
        curVal = inputElem.value.trim();
        break;

      case 'checkbox':
        curVal = (inputElem as HTMLInputElement).checked;
        break;

      case 'radio':
      case 'select':
        curVal = inputElem.value;
        break;

      case 'images':
      {
        const files = (inputElem as HTMLInputElement).files;
        if (!files?.length) return;

        curVal = this.getCurVal(field) ?? [];

        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          const data = await new Promise<string | ArrayBuffer | null | undefined>((resolve, reject) => {
            reader.onload = (fileData) => resolve(fileData.target?.result);
            reader.onerror = (err) => reject(err);
            reader.readAsArrayBuffer(files[i]);
          });

          if (!data) {
            alert('Fehler beim Lesen der Datei!');
            continue;
          }

          // upload data directly to the server
          try {
            const fileId = await this.uploadFile(data, files[i].type, files[i].name);
            curVal.push({ id: fileId, description: '' });
          } catch (err) {
            console.error(err);
            alert(`Fehler beim Hochladen der Datei zum Server!\n\n${err}`);
          }

        }

        break;
      }

      default:
        console.warn(ev);
        alert(`Unhandled form field type "${field.type}"!`);
        return;
    }

    // set value and send to server on change events
    this.setCheckoutValue(field, curVal, ev.type === 'change');

  }

  private async sendChangesToServer (): Promise<void> {
    if (!formData?.checkout) return;

    this.uploadIndicator.classList.add('active');

    try {
      // create a copy of the changed data to keep it untouched on changes during async stuff
      const changes: FromChanges = {
        values: JSON.parse(JSON.stringify(formData.checkout.values)),
        collapsed: JSON.parse(JSON.stringify(formData.checkout.collapsed ?? [])),
      };

      await this.q.enqueue(async () => {
        const res = await fetch(`${base}forms/${formData.id}/change`, {
          method: 'PATCH',
          body: JSON.stringify(changes),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });

        const json = await res.json();

        if (json.error) {
          alert(`Konnte Änderungen nicht an den Server übermitteln!\n\n${json.error}`);
        }
      });

    } catch (err) {
      console.error(err);
      alert(`Konnte Änderungen nicht an den Server übermitteln!\n\n${err}`);
    }

    this.uploadIndicator.classList.remove('active');
  }

  /**
   * Upload a file to the server.
   *
   * The file info will be stored into `formData.files`.
   *
   * @param data The file data.
   * @param mime Mime type of the file.
   * @param fileName Name of the file including file extension.
   * @returns The ID of the uploaded file.
   * @throws `Error` in case of an error.
   */
  private async uploadFile (data: string | ArrayBuffer, mime: string, fileName: string): Promise<string> {
    if (!formData || !this.editing) {
      throw new Error('not allowed');
    }

    this.uploadIndicator.classList.add('active');

    const res = await fetch(`${base}forms/${formData.id}/upload`, {
      method: 'PUT',
      body: data,
      headers: {
        'Content-type': 'application/octet-stream',
      },
    });
    if (res.status !== 200) {
      this.uploadIndicator.classList.remove('active');
      throw new Error(`HTTP ${res.status} ${res.statusText}`);
    }
    const json = await res.json() as { error?: string, ok?: true, fileInfo?: FormFileInfo };

    if (json.error) {
      this.uploadIndicator.classList.remove('active');
      throw new Error(json.error);
    }

    if (!json.fileInfo) {
      this.uploadIndicator.classList.remove('active');
      throw new Error('No file info from server');
    }

    if (typeof formData.files !== 'object') {
      formData.files = {};
    }
    formData.files[json.fileInfo.id] = json.fileInfo;

    // send PATCH request to server to set some file meta data
    const meta: Partial<FormFileInfo> = {
      id: json.fileInfo.id,
      mime,
      name: fileName,
    };
    const res2 = await fetch(`${base}forms/${formData.id}/upload/${json.fileInfo.id}`, {
      method: 'PATCH',
      body: JSON.stringify(meta),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    if (res2.status !== 200) {
      this.uploadIndicator.classList.remove('active');
      throw new Error(`Meta HTTP ${res2.status} ${res2.statusText}`);
    }

    const json2 = await res2.json();
    formData.files[json.fileInfo.id] = json2.fileInfo;

    this.uploadIndicator.classList.remove('active');
    return json.fileInfo.id;
  }

  private getCurVal<T extends FormFieldInput> (field: T): T['value'] | undefined {
    if (!formData?.checkout) {
      return field.value;
    }

    let value: FormValue | undefined = formData.checkout.values[field.id];
    if (typeof value === 'undefined') {
      value = field.value;
    }

    if (typeof field.value === 'object') {
      // need to create a deep copy to prevent modifying the original object
      return JSON.parse(JSON.stringify(value));
    }

    return value;
  }

  private setCheckoutValue<T extends FormFieldWithElements<FormFieldInput>> (field: T, val: T['value'], sendToServer: boolean): void {
    if (!formData?.checkout) return;

    if (typeof val !== 'undefined' && JSON.stringify(field.value) !== JSON.stringify(val)) {
      // value changed
      formData.checkout.values[field.id] = val;
    } else {
      // value is like the original value
      delete formData.checkout.values[field.id];
    }

    // write back the value to display the possible changes
    this.setElementValue(field, val);

    this.updateChangedState();

    if (sendToServer) {
      this.sendChangesToServer();
    }
  }

  private setElementValue<T extends FormFieldWithElements<FormFieldInput>> (field: T, value: T['value'] | undefined): void {
    if (typeof value === 'undefined') {
      value = field.value;
    }

    switch (field.type) {
      case 'text':
        field.inputElems[0].value = value as string;

        if (field.inputElems[0].type === 'textarea') {
          resizeTextarea(field.inputElems[0] as HTMLTextAreaElement);
        }
        break;
      case 'number':
      case 'calc':
        if (typeof value === 'number') {
          if (typeof field.decimals === 'number') {
            field.inputElems[0].value = value.toFixed(field.decimals).replace('.', ',');
          } else {
            field.inputElems[0].value = value.toString(10).replace('.', ',');
          }
        } else if (typeof value === 'string') {
          field.inputElems[0].value = value;
        } else {
          field.inputElems[0].value = '';
        }
        // store current input value in data-value - needed for css to display negative numbers in red
        field.inputElems[0].dataset['value'] = field.inputElems[0].value;
        break;
      case 'checkbox':
        (field.inputElems[0] as HTMLInputElement).checked = value as boolean;
        break;
      case 'radio':
        for (const inputElem of field.inputElems) {
          (inputElem  as HTMLInputElement).checked = inputElem.value === value;
        }
        break;
      case 'date':
      case 'select':
        field.inputElems[0].value = value as string;
        break;

      case 'images':
        this.updateImagesContainer(field);
        break;

      case 'signatures':
        this.updateSignaturesContainer(field);
        break;

      default:
        console.warn(`Unhandled field type ${field.type} to set value`);
    }

    if (JSON.stringify(field.value) !== JSON.stringify(value)) {
      field.containerElem.classList.add('changed');
    } else {
      field.containerElem.classList.remove('changed');
    }
  }

  private updateChangedState (): void {
    if (!formData?.checkout || !this.editing) return;

    const changedFields = Object.getOwnPropertyNames(formData.checkout.values);

    this.hasChanges = (changedFields.length > 0);

    this.inputDisable(this.btnRevert, !this.hasChanges, 'hasChanged');
    this.inputDisable(this.btnSave, !this.hasChanges, 'hasChanged');
  }

  private setupCalcField (field: FormFieldWithElements<FormFieldCalc>): void {
    if (!formData || !field.expression || ! Array.isArray(field.dependsOn)) return;

    // field IDs may be given like `theId[*]` for fields with multiple IDs (like in extendable fields)
    /**
     * The full list of resolved IDs of depending fields
     */
    const dependsOn: string[] = [];

    for (const id of field.dependsOn) {
      if (id.endsWith('[*]')) {
        // field with multiple IDs... find them all
        const baseId = id.replace(/\[\*\]$/, '');
        const ids: string[] = [];
        const fields = findFormFields(formData.fields, (f) => f.id.startsWith(`${baseId}[`) && f.id.endsWith(']'));
        for (const f of fields) {
          ids.push(f.id);
        }

        // replace the placeholder in the field expression
        field.expression = field.expression.replace(id, ids.map((i) => sanitizeFieldId(i)).join(', '));

        dependsOn.push(...ids);
      } else {
        // single ID
        dependsOn.push(id);

        // replace the id in the expression with a "cleaned" id since the id may include special characters
        field.expression = field.expression.replace(id, sanitizeFieldId(id));
      }
    }

    const calcField = (): void => {
      const evalOptions: Record<string, ExprEval.Value> = {};

      for (const depFieldId of dependsOn) {
        const depField = findFormFieldById(formData.fields, depFieldId);
        if (!depField) {
          alert(`Fehler in berechnetem Feld ${field.label} (${field.id}):\nEin abhängiges Feld (${depFieldId}) wurde nicht gefunden.\nDie Berechnung kann nicht korrekt durchgeführt werden.`);
          return;
        }
        if (!isInputFormField(depField)) {
          alert(`Fehler in berechnetem Feld ${field.label} (${field.id}):\nDas abhängige Feld ${depField.label} (${depFieldId}) hat keinen für Berechnungen zulässigen Typ.\nDie Berechnung kann nicht korrekt durchgeführt werden.`);
          return;
        }
        const depVal = this.getCurVal(depField);

        // if one field is null, the result should be null too
        if (depVal === null && field.needAllDependencies !== false) {
          this.setCheckoutValue(field, null, true);
          return;
        }

        if (depVal === null) {
          evalOptions[sanitizeFieldId(depFieldId)] = 0;
        } else if (typeof depVal === 'string' || typeof depVal === 'number') {
          evalOptions[sanitizeFieldId(depFieldId)] = depVal;
        } else {
          alert(`Fehler in berechnetem Feld ${field.label} (${field.id}):\nDer Wert des abhängigen Felds ${depField.label} (${depFieldId}) ist ungültig.\nDie Berechnung kann nicht korrekt durchgeführt werden.`);
          return;
        }
      }

      // calc using try/catch to catch possible error during calculation
      let val: number;
      try {
        val = this.parser.evaluate(field.expression, evalOptions);
      } catch (err) {
        console.error(`Error while calculating field ${field.label} (${field.id}):\n`, err,
          `\nExpression:`, field.expression,
          `\nValues:`, evalOptions,
        );
        alert(`Fehler in berechnetem Feld ${field.label} (${field.id}):\nBei der Berechnung ist ein Fehler aufgetreten:\n${err}\nDie Berechnung kann nicht korrekt durchgeführt werden.`);
        return;
      }

      if (typeof field.decimals === 'number' && field.decimals > 0) {
        const e = Math.pow(10, field.decimals);
        val = Math.round(val * e) / e;
      }

      this.setCheckoutValue(field, val, true);
    };

    if (this.editing) {
      calcField();
    }

    for (const depFieldId of dependsOn) {
      // get input fields from DOM
      const inputContainer = document.getElementById(`formfield-container-${depFieldId}`);
      if (!inputContainer) {
        alert(`Fehler in berechnetem Feld ${field.label} (${field.id}):\nEin abhängiges Feld (${depFieldId}) wurde nicht gefunden. Die Berechnung kann nicht korrekt durchgeführt werden.`);
        continue;
      }

      // get formfield inputs in the container
      const inputs = inputContainer.querySelectorAll<HTMLInputOrTextareaElement>('.formfield');
      inputs.forEach((elem) => {
        // need to use setTimeout here to run the calc on the next tick
        // this will let the input field handle the change first and then run calcField
        elem.addEventListener('change', () => setTimeout(() => calcField(), 0));
      });
    }
  }


  private setupSelectField (field: FormFieldWithElements<FormFieldSelect>): void {
    if (!formData || !this.editing) return;

    for (const optionIdx in field.options) {
      const option = field.options[optionIdx];
      if (typeof option !== 'object' || !option.showIf || !option.dependsOn) {
        continue;
      }

      /**
       * The full list of resolved IDs of depending fields
       */
      const dependsOn: string[] = [];

      for (const id of option.dependsOn) {
        dependsOn.push(id);

        // replace the id in the expression with a "cleaned" id since the id may include special characters
        option.showIf = option.showIf.replace(id, sanitizeFieldId(id));
      }

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const checkShowOption = (): void => {
        const evalOptions: Record<string, ExprEval.Value> = {};

        for (const depFieldId of dependsOn) {
          const depField = findFormFieldById(formData.fields, depFieldId);
          if (!depField) {
            alert(`Fehler in Feld ${field.label} (${field.id}):\nEin abhängiges Feld (${depFieldId}) wurde nicht gefunden.\nVerfügbare Optionen können nicht korrekt ermittelt werden.`);
            return;
          }
          if (!isInputFormField(depField)) {
            alert(`Fehler in Feld ${field.label} (${field.id}):\nDas abhängige Feld ${depField.label} (${depFieldId}) hat keinen für Bedingung zulässigen Typ.\nVerfügbare Optionen können nicht korrekt ermittelt werden.`);
            return;
          }
          const depVal = this.getCurVal(depField);

          if (depVal === null) {
            evalOptions[sanitizeFieldId(depFieldId)] = '';
          } else if (typeof depVal === 'string' || typeof depVal === 'number') {
            evalOptions[sanitizeFieldId(depFieldId)] = depVal;
          } else {
            alert(`Fehler in Feld ${field.label} (${field.id}):\nDer Wert des abhängigen Felds ${depField.label} (${depFieldId}) ist ungültig.\nVerfügbare Optionen können nicht korrekt ermittelt werden.`);
            return;
          }
        }

        // evaluate using try/catch to catch possible error during evaluation
        let show: boolean = true;
        try {
          show = !!this.parser.evaluate(option.showIf!, evalOptions);
        } catch (err) {
          console.error(err);
          alert(`Fehler in Feld ${field.label} (${field.id}) Option ${optionIdx}:\n${err}\nVerfügbare Optionen können nicht korrekt ermittelt werden.`);
          return;
        }

        const elem = document.getElementById(`formfield-${field.id}-opt${optionIdx}`);
        if (!elem) {
          return;
        }
        if (show && elem.classList.contains('hide-because-showIf')) {
          elem.classList.remove('hide-because-showIf');
        } else if (!show && !elem.classList.contains('hide-because-showIf')) {
          elem.classList.add('hide-because-showIf');
        }
      };

      checkShowOption();

      for (const depFieldId of dependsOn) {
        // get input fields from DOM
        const inputContainer = document.getElementById(`formfield-container-${depFieldId}`);
        if (!inputContainer) {
          alert(`Fehler in Feld ${field.label} (${field.id}):\nEin abhängiges Feld (${depFieldId}) wurde nicht gefunden. Verfügbare Optionen können nicht korrekt ermittelt werden.`);
          continue;
        }

        // get formfield inputs in the container
        const inputs = inputContainer.querySelectorAll<HTMLInputOrTextareaElement>('.formfield');
        inputs.forEach((elem) => {
          // need to use setTimeout here to run the check on the next tick
          // this will let the input field handle the change first and then run checkShowOption
          elem.addEventListener('change', () => setTimeout(() => checkShowOption(), 0));
        });
      }
    }
  }

  private updateImagesContainer (field: FormFieldWithElements<FormFieldImages>): void {
    if (!formData) return;

    const tpl = field.containerElem.querySelector<HTMLElement>('script[type="template/ejs"]')?.innerHTML;
    const imgContainer = field.containerElem.querySelector<HTMLElement>('.images-container');
    if (!tpl || !imgContainer) return;

    const curVal = this.getCurVal(field);
    if (!Array.isArray(curVal)) return;

    // disable upload and sketch inputs/buttons when max number of images is reached
    const disableAdd = (typeof field.max === 'number' && curVal.length >= field.max);
    this.inputDisable(field.inputElems[0], disableAdd, 'maxImages');
    this.inputDisable(field.inputElems[0].parentElement?.querySelector('label'), disableAdd, 'maxImages');
    this.inputDisable(field.inputElems[1], disableAdd, 'maxImages');

    let html: string = '';

    for (const { id, description } of curVal) {
      const fileInfo = formData.files?.[id];
      if (!fileInfo) continue;
      html += ejs.render(tpl, { img: {
        id,
        src: `forms/${formData.id}/file/${fileInfo.id}/${fileInfo.name}`,
        alt: fileInfo.name,
        description,
      } });
    }

    imgContainer.innerHTML = html;

    // activate description inputs
    const inputs = imgContainer.querySelectorAll<HTMLInputElement>('input.formfield-imagedesc');
    inputs.forEach((inp) => {
      this.inputDisable(inp, !this.editing, 'notEditing');

      // only handle onchange here since this will re-render the whole images elements
      inp.onchange = () => {
        // update description
        const imgId = inp.dataset.id;
        const actVal = this.getCurVal(field);
        if (!imgId || !actVal) return;

        const iv = actVal.find((a) => a.id === imgId);
        if (!iv) return;

        iv.description = inp.value.trim();

        this.setCheckoutValue(field, actVal, true);
      };

    });

    // activate delete buttons
    let buttons = imgContainer.querySelectorAll<HTMLButtonElement>('button.image-del');
    buttons.forEach((btn) => {
      const imgId = btn.parentElement?.parentElement?.querySelector('img')?.dataset.id;
      if (this.editing && imgId) {
        btn.onclick = () => {
          if (confirm('Soll dieses Bild wirklich gelöscht werden?\n\nHinweis: Bis zum Speichern kann dies über "Änderungen verwerfen" noch rückgängig gemacht werden.')) {
            let val = this.getCurVal(field) ?? [];
            val = val.filter((v) => v.id !== imgId);
            this.setCheckoutValue(field, val, true);
          }
        };
      }
    });
    // activate edit buttons
    buttons = imgContainer.querySelectorAll<HTMLButtonElement>('button.image-edit');
    buttons.forEach((btn) => {
      const imgId = btn.parentElement?.parentElement?.querySelector('img')?.dataset.id;
      if (this.editing && imgId) {
        btn.onclick = () => {
          this.openImageEditor(imgId, field);
        };
      }
    });
  }


  private updateSignaturesContainer (field: FormFieldWithElements<FormFieldSignatures>): void {
    // do nothing if no formData or not editing
    if (!formData) return;

    const sigContainer = field.containerElem.querySelector<HTMLElement>('.signatures-container');
    const sigActiveContainer = field.containerElem.parentElement?.querySelector<HTMLDivElement>(`#formfield-container-active-${field.id}`);
    const sigDataAll = this.getCurVal(field);

    if (!sigContainer || !sigActiveContainer || !sigDataAll) return;

    for (const sigData of sigDataAll) {
      const img = sigContainer.querySelector<HTMLImageElement>(`#signature-image-${field.id}-${sigData.id}`);
      const noImg = sigContainer.querySelector<HTMLImageElement>(`#signature-noimage-${field.id}-${sigData.id}`);
      const txtTitle = sigContainer.querySelector<HTMLElement>(`#signature-title-${field.id}-${sigData.id}`);
      const txtLocation = sigContainer.querySelector<HTMLElement>(`#signature-location-${field.id}-${sigData.id}`);
      const txtDate = sigContainer.querySelector<HTMLElement>(`#signature-date-${field.id}-${sigData.id}`);
      if (!img || !noImg || !txtTitle || !txtLocation || !txtDate) continue;

      if (sigData.signature) {
        img.src = sigData.signature;
        img.classList.remove('w3-hide');
        noImg.classList.add('w3-hide');
        txtTitle.innerHTML = sigData.title;
        txtLocation.innerHTML = sigData.location;
        txtDate.innerHTML = formatDate(sigData.date, 'DD.MM.YYYY');
      } else {
        img.src = '';
        img.classList.add('w3-hide');
        noImg.classList.remove('w3-hide');
        txtTitle.innerHTML = sigData.title;
        txtLocation.innerHTML = 'Ort';
        txtDate.innerHTML = 'Datum';
      }
    }

    const sigButtons = sigContainer.querySelectorAll<HTMLButtonElement>('button.signature-button');
    sigButtons.forEach((sigButton) => {
      this.inputDisable(sigButton, !this.editing, 'notEditing');
      sigButton.onclick = () => this.handleSignatureClick(field, sigButton, sigActiveContainer);
    });
  }

  private handleSignatureClick (field: FormFieldWithElements<FormFieldSignatures>, sigButton: HTMLButtonElement, sigActiveContainer: HTMLDivElement): void {
    // do nothing if no formData or not editing
    if (!formData?.checkout || !this.editing) return;

    // check if there are unsaved changes (ignoring this signatures)
    const changedKeys = Object.getOwnPropertyNames(formData.checkout.values).filter((k) => k !== field.id);
    if (changedKeys.length > 0) {
      alert('Es gibt Änderungen im Formular, die nicht gespeichert wurden.\n\nBitte vor dem Unterschreiben die Änderungen speichern.');
      return;
    }

    const sigId = sigButton.dataset.id;
    if (!sigId) return;

    const sigDataAll = this.getCurVal(field);
    if (!sigDataAll?.[0]) return;

    const sigData = sigDataAll.find((s) => s.id === sigId);
    if (!sigData) return;

    const inpTitle = sigActiveContainer.querySelector<HTMLInputElement>(`#formfield-${field.id}-signature-title`);
    const inpLocation = sigActiveContainer.querySelector<HTMLInputElement>(`#formfield-${field.id}-signature-location`);
    const elemDate = sigActiveContainer.querySelector<HTMLElement>(`#formfield-${field.id}-signature-date`);
    const btnApply = sigActiveContainer.querySelector<HTMLButtonElement>(`#formfield-${field.id}-signature-apply`);
    const btnReset = sigActiveContainer.querySelector<HTMLButtonElement>(`#formfield-${field.id}-signature-reset`);
    const btnCancel = sigActiveContainer.querySelector<HTMLButtonElement>(`#formfield-${field.id}-signature-cancel`);
    const btnRemove = sigActiveContainer.querySelector<HTMLButtonElement>(`#formfield-${field.id}-signature-remove`);
    const canvas = sigActiveContainer.querySelector<HTMLCanvasElement>('canvas');
    if (!inpTitle || !elemDate || !inpLocation || !btnApply || !btnReset || !btnCancel || !btnRemove || !canvas) return;

    // fill in the values
    inpTitle.value = sigData.title;
    inpLocation.value = sigData.location || sigDataAll[0].location || '';

    // replace template vars for location
    inpLocation.value = replaceTemplateVarFieldValues(inpLocation.value, formData);

    sigActiveContainer.classList.remove('w3-hide');

    const sigPad = new SignaturePad(canvas);

    // need to resize the pad initially and on window resized to get correct pen posions
    const resizeSigPad = (): void => {
      const tmp = sigPad.isEmpty() ? null : sigPad.toData();

      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);
      sigPad.clear();

      if (tmp) sigPad.fromData(tmp);
    };
    resizeSigPad();

    const updateResetButton = (): void => {
      this.inputDisable(btnReset, sigPad.isEmpty(), 'sigPadEmpty');
    };

    const unloadSigPad = (): void => {
      sigPad.off();

      window.removeEventListener('resize', resizeSigPad);
      sigPad.removeEventListener('afterUpdateStroke', updateResetButton);

      sigActiveContainer.classList.add('w3-hide');

      this.updateSignedState();
    };

    window.addEventListener('resize', resizeSigPad);

    // load signature if saved and lock inputs
    if (sigData.signature) {
      sigPad.fromDataURL(sigData.signature);
      sigPad.off();

      elemDate.innerHTML = formatDate(sigData.date, 'DD.MM.YYYY');
      inpTitle.disabled = true;
      inpLocation.disabled = true;
      btnApply.disabled = true;
      btnApply.classList.add('w3-hide');
      btnRemove.disabled = false;
      btnRemove.classList.remove('w3-hide');
      btnReset.disabled = true;
      btnReset.classList.add('w3-hide');
    } else {
      sigPad.on();
      sigPad.clear();

      elemDate.innerHTML = formatDate(Date.now(), 'DD.MM.YYYY');
      inpTitle.disabled = false;
      inpLocation.disabled = false;
      btnApply.disabled = false;
      btnApply.classList.remove('w3-hide');
      btnRemove.disabled = true;
      btnRemove.classList.add('w3-hide');
      btnReset.disabled = true;
      btnReset.classList.remove('w3-hide');

      btnReset.onclick = () => {
        sigPad.clear();
        updateResetButton();
      };

      sigPad.addEventListener('afterUpdateStroke', updateResetButton);
    }


    btnCancel.onclick = unloadSigPad;

    btnApply.onclick = () => {
      const title = inpTitle.value.trim();
      const location = inpLocation.value.trim();
      const date = new Date().toISOString();

      if (!title || !date || !location || sigPad.isEmpty()) {
        alert('Alle Felder müssen ausgefüllt und eine Unterschrift muss geleistet werden!');
        return;
      }

      sigData.title = title;
      sigData.date = date;
      sigData.location = location;
      sigData.signature = sigPad.toDataURL();

      sigData.hash = calcFieldsHash(formData.fields, sigData);

      this.setCheckoutValue(field, sigDataAll, true);

      unloadSigPad();
    };

    btnRemove.onclick = () => {
      if (!confirm('Soll diese Unterschrift wirklich entfernt werden?')) return;

      sigData.signature = '';
      sigData.hash = '';

      this.setCheckoutValue(field, sigDataAll, true);

      unloadSigPad();
    };
  }

  private updateSignedState (): void {
    const sigFields = findFormFields<FormFieldSignatures>(formData?.fields, (f) => f.type === 'signatures');

    let signed = false;

    for (const field of sigFields) {
      const curVal = this.getCurVal(field);
      if (curVal) {
        for (const s of curVal) {
          if (s.signature) {
            signed = true;
          }
        }
      }
    }

    this.signed = signed;

    const elems = document.querySelectorAll<HTMLElement>('.disable-signed');
    elems.forEach((elem) => {
      this.inputDisable(elem, this.signed, 'signed');
    });
  }

  private isCollapsed (fieldId: string): boolean {
    return !! formData?.checkout?.collapsed?.includes(fieldId);
  }

  private handleGroupCollapseButton (elem: HTMLButtonElement): void {
    if (!this.editing) return;

    const fieldId = elem.dataset.id ?? elem.id.replace(/^formfield-group-collapse-btn-/, '');

    const collapsed = this.isCollapsed(fieldId);

    // set collapsed state and send changes to server
    this.setCollapsed(fieldId, !collapsed, true);
  }

  private setCollapsed (fieldId: string, collapsed?: boolean, sendToServer?: boolean): void {
    if (!formData?.checkout) return;

    const elem = document.getElementById(`formfield-group-${fieldId}`);
    if (!elem) return;

    if (!formData.checkout.collapsed) {
      formData.checkout.collapsed = [];
    }

    if (typeof collapsed === 'undefined') {
      collapsed = this.isCollapsed(fieldId);
    }

    if (collapsed) {
      elem.classList.add('collapsed');
      formData.checkout.collapsed.push(fieldId);
    } else {
      elem.classList.remove('collapsed');
      formData.checkout.collapsed = formData.checkout.collapsed.filter((f) => f !== fieldId);
    }

    if (sendToServer) {
      this.sendChangesToServer();
    }
  }

  private inputDisable (elem: HTMLElement | undefined | null, disable: boolean, reason: string): void {
    if (!elem) return;

    const reasons = elem.dataset.disableReasons ? new Set(elem.dataset.disableReasons.split(',')) : new Set<string>();

    if (disable) {
      reasons.add(reason);
    } else {
      reasons.delete(reason);
    }

    elem.dataset.disableReasons = [ ...reasons ].join(',');

    switch (elem.tagName.toLowerCase()) {
      case 'button':
      case 'input':
      case 'select':
      case 'textarea':
        (elem as HTMLInputElement).disabled = (reasons.size > 0);
        break;
      default:
        if (reasons.size > 0) {
          elem.classList.add('w3-disabled');
        } else {
          elem.classList.remove('w3-disabled');
        }
    }
  }

  /**
   * Load and open an image editor.
   *
   * If the image editor script is not already loaded, this will be loaded async first.
   *
   * @param loadImgId The file ID of the image to load or `null` to create a new sketch.
   * @param field The related images form field.
   */
  private async openImageEditor (loadImgId: string | null, field: FormFieldWithElements<FormFieldImages>): Promise<void> {
    if (!formData || !this.editing) return;

    // load js/css async
    if (typeof initImageEditor === 'undefined') {
      await Promise.all([
        new Promise<void>((resolve) => {
          const css = document.createElement('link');
          css.rel = 'stylesheet';
          css.href = 'image-editor.css';
          css.onload = () => resolve();
          document.head.appendChild(css);
        }),

        new Promise<void>((resolve) => {
          const script = document.createElement('script');
          script.id = 'image-editor-script';
          script.src = 'image-editor.js';
          script.onload = () => resolve();
          document.body.appendChild(script);
        }),
      ]);
    }

    if (typeof initImageEditor === 'undefined') {
      alert('Fehler: Der ImageEditor konnte nicht geladen werden!');
      return;
    }

    let fileInfo: FormFileInfo | undefined;
    let imagePath: string;
    let imageName: string;

    if (loadImgId) {
      // load a file
      fileInfo = formData.files?.[loadImgId];
      if (!fileInfo) {
        alert('Fehler: Keine Daten zum Bild gefunden!');
        return;
      }
      imagePath = `forms/${formData.id}/file/${fileInfo.id}/${fileInfo.name}`;
      imageName = fileInfo.name;
    } else {
      // create a clean image
      const cv = document.createElement('canvas');
      cv.width = 1024;
      cv.height = 768;
      const ctx = cv.getContext('2d');
      if (ctx) {
        ctx.rect(0, 0, 1024, 768);
        ctx.fillStyle = '#ffffff';
        ctx.fill();
      }

      imagePath = cv.toDataURL();
      imageName = 'sketch.jpg';
    }

    const container = document.createElement('div');
    container.id = 'image-editor';
    document.body.appendChild(container);

    const editor = initImageEditor({
      container,
      imagePath,

      onSave: async (buf: ArrayBuffer) => {
        // save new file and update the images field value

        let newImgId: string;
        try {
          newImgId = await this.uploadFile(buf, 'image/jpg', imageName.replace(/.[^.]+$/, '.jpg'));
        } catch (err) {
          console.error(err);
          alert(`Fehler beim Hochladen der Datei zum Server!\n\n${err}`);
          return;
        }

        const curVal = this.getCurVal(field) ?? [];

        const entry = curVal.find((v) => v.id === loadImgId);
        if (entry) {
          // replace existing entry by setting the new file ID
          entry.id = newImgId;
        } else {
          // add new entry
          curVal.push({
            id: newImgId,
            description: '',
          });
        }

        // save changed entries
        this.setCheckoutValue(field, curVal, true);

        // destroy the editor
        editor.destroy();
        container.remove();
      },

      onDiscard: () => {
        // destroy the editor
        editor.destroy();
        container.remove();
      },
    });
  }
}
