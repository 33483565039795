import dayjs from 'dayjs';
import { cyrb53 } from './cyrb53';
import { FormData, FormDefinitionMailOptions, FormField, FormFieldBase, FormFieldInput, FormFieldSignatures, FormFieldTypesInput, FormValueSignature } from './form-types';
import { UserInfo } from './generic-types';

/**
 * UUIDv4 generator (RFC4122 compliant)
 * @deprecated Use crypto.randomUUID instead
 */
export function uuid4 (): string {
  let uuid = '';
  for (let i = 0; i < 32; i++) {
    const random = Math.random() * 16 | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    // eslint-disable-next-line no-mixed-operators
    uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
  }
  return uuid;
}

export function formatDate (date: Date | number | string | null, format: string = 'DD.MM.YYYY HH:mm:ss'): string {
  if (!date) {
    return '';
  }
  return dayjs(date).format(format);
}

export function findFormFields<T extends FormFieldBase = FormField> (data: FormField[] | undefined, matcher: (field: FormField) => boolean): T[] {
  if (!data) {
    return [];
  }

  const result: T[] = [];
  const iter = (f: FormField): void => {
    if (matcher(f)) {
      result.push(f as unknown as T);
    }

    if ((f.type === 'include' || f.type === 'group' || f.type === 'checkboxes') && Array.isArray(f.fields)) {
      f.fields.forEach(iter);
    } else if ((f.type === 'grid' || f.type === 'extendable') && Array.isArray(f.fields)) {
      f.fields.forEach((ff) => ff.forEach(iter));
    }
  };

  data.forEach(iter);

  return result;
}

export function findFormField<T extends FormFieldBase = FormField> (data: FormField[] | undefined, matcher: (field: FormField) => boolean): T | undefined {
  if (!data) {
    return undefined;
  }

  return findFormFields<T>(data, matcher)[0];
}

export function findFormFieldById<T extends FormFieldBase = FormField> (data: FormField[] | undefined, id: string): T | undefined {
  if (!data) {
    return undefined;
  }

  return findFormField<T>(data, (f) => f.id === id);
}

export function getPlainFieldArrayCopy<T extends FormField = FormField> (fields: T[]): T[] {
  // create a copy of the fields with all fields at top level
  const fieldsCopy = findFormFields<T>(JSON.parse(JSON.stringify(fields)) as FormField[], () => true);

  // remove all sub fields - they are at top level too now
  for (const field of fieldsCopy) {
    switch (field.type) {
      case 'checkboxes':
      case 'extendable':
      case 'grid':
      case 'group':
      case 'include':
        field.fields = [];
        break;
      default:
        // nothing to do
    }
  }

  return fieldsCopy;
}

/**
 * Check if a FormField is a FormFieldInput.
 * @param field The FormField to check.
 * @returns `true` if the FormField is a FormFieldInput, `false` otherwise.
 */
export function isInputFormField (field: FormField): field is FormFieldInput {
  return (FormFieldTypesInput as unknown as string[]).includes(field.type);
}

/**
 * Get the full sequential number of a form.
 * @param form The form data.
 * @returns The full sequential number of the form or an empty string.
 */
export function getFormSequentialNumber (form: FormData): string {
  switch (form.hasSequentialNumber) {
    case 'multiple': return `${form.sequentialNumber}${form.sequentialNumberSub}`;
    case 'single': return `${form.sequentialNumber}`;
    default: return '';
  }
}

/**
 * Replace template variables with field values in a string.
 *
 * The template variables needs to be given like `${fieldId}`.
 * The variable name is the ID of a form field. If no field with this ID is
 * found, the variable will just be replaced by an empty string.
 * @param str The string to replace field values in.
 * @param form The form data.
 * @param additionalData Optional mapping of additional keys to values to be replaced.
 * @param htmlEscaped `true` to escape chars to be save in html usage.
 * @returns The string with all template strings replaced.
 */
export function replaceTemplateVarFieldValues (str: string, form: FormData, additionalData?: Record<string, string | number | boolean | null>, htmlEscaped?: boolean): string {
  return str.replace(/\$\{([\w-]+)\}/g, (_m: string, n: string) => {
    /** Helper function to get the value */
    const getVal = (): string => {
      switch (n) {
        case 'SEQUENTIAL_NR':
          return getFormSequentialNumber(form);
        case 'FORM_ID':
          return form.id ?? '';
        case 'FORM_DATE':
          return formatDate(form.updatedAt ?? form.createdAt ?? Date.now(), 'YYYY-MM-DD_HH-mm-ss');
        case 'FORM_TITLE':
        {
          if (form.titleField) {
            const field = findFormFieldById(form.fields, form.titleField);
            if (!field || !isInputFormField(field) || field.value === undefined || field.value === null) return '';
            return (typeof field.value === 'string') ? field.value : field.value.toString();
          }
          if (form.titleTemplate) {
            return replaceTemplateVarFieldValues(form.titleTemplate, form);
          }
          return form.id ?? '';
        }
      }

      // key in extra data?
      if (typeof additionalData?.[n] !== 'undefined') {
        const value = additionalData[n];
        if (value === null) return '';
        return (typeof value === 'string') ? value : value.toString();
      }

      // form field
      const field = findFormFieldById(form.fields, n);
      if (!field || !isInputFormField(field) || field.value === undefined || field.value === null) return '';
      return (typeof field.value === 'string') ? field.value : field.value.toString();
    };

    if (htmlEscaped) {
      // return the value fully html escaped
      // @source https://stackoverflow.com/a/18750001/7136720
      return getVal().replace(/[\u00A0-\u9999<>&]/g, (i) => '&#' + i.charCodeAt(0) + ';');
    }

    return getVal();
  });
}

export function calcFieldsHash (fields: FormField[], signature?: FormValueSignature): string {
  // copy the object
  let data = getPlainFieldArrayCopy(fields);

  // filter out all signature fields
  data = findFormFields(data, (f) => f.type !== 'signatures');

  // create a string
  let dataStr = JSON.stringify(data);

  // add signature data if provided
  if (signature) {
    const data2 = JSON.parse(JSON.stringify(signature)) as FormValueSignature;
    data2.hash = '';
    dataStr += JSON.stringify(data2);
  }

  // calc hash
  return `cyrb53#${cyrb53(dataStr)}`;
}

export function checkSignatures (fields: FormField[]): boolean {

  const sigFields = findFormFields<FormFieldSignatures>(fields, (f) => f.type === 'signatures');

  for (const sigField of sigFields) {
    for (const sig of sigField.value) {
      // nothing to check if no signature
      if (!sig.signature) continue;

      // fail if no hash
      if (!sig.hash) return false;

      // compare saved hash with current hast
      const curHash = calcFieldsHash(fields, sig);
      if (curHash !== sig.hash) return false;
    }
  }

  return true;
}

/**
 * Sanitize a filed ID to be used in e.g. calculations.
 * This will replace all non-word characters by an underscore `_`.
 * @param id The field ID.
 * @returns The sanitized field ID.
 */
export function sanitizeFieldId (id: string): string {
  return id.replace(/\W/g, '_');
}

/**
 * Get ready to use email targets from form email options.
 * @param formDataEmail Email options from the form data/definition.
 * @param userData Optional user data to replace `${USER}` placeholders.
 * @returns Object containing arrays of to, cc, bcc and replyTo addresses.
 */
export function getEmailTargets (formDataEmail: FormDefinitionMailOptions, userData?: UserInfo | null): { to: string[], cc: string[], bcc: string[], replyTo: string[] } {
  let to: string[];
  let cc: string[];
  let bcc: string[];
  let replyTo: string[];

  if (typeof formDataEmail.to === 'string') {
    to = [ formDataEmail.to ];
  } else if (Array.isArray(formDataEmail.to)) {
    to = [ ...formDataEmail.to ];
  } else {
    to = [];
  }

  if (typeof formDataEmail.cc === 'string') {
    cc = [ formDataEmail.cc ];
  } else if (Array.isArray(formDataEmail.cc)) {
    cc = [ ...formDataEmail.cc ];
  } else {
    cc = [];
  }

  if (typeof formDataEmail.bcc === 'string') {
    bcc = [ formDataEmail.bcc ];
  } else if (Array.isArray(formDataEmail.bcc)) {
    bcc = [ ...formDataEmail.bcc ];
  } else {
    bcc = [];
  }

  if (typeof formDataEmail.replyTo === 'string') {
    replyTo = [ formDataEmail.replyTo ];
  } else if (Array.isArray(formDataEmail.replyTo)) {
    replyTo = [ ...formDataEmail.replyTo ];
  } else {
    replyTo = [];
  }

  // replace `${USER}` placeholders and filter out empty values
  const mapFn = (v: string): string => {
    if (v !== '${USER}') return v;
    if (!userData?.email) return '';
    if (!userData.name) return userData.email;
    return `"${userData.name}" ${userData.email}`;
  };
  to = to.map(mapFn).filter((v) => !!v);
  cc = cc.map(mapFn).filter((v) => !!v);
  bcc = bcc.map(mapFn).filter((v) => !!v);
  replyTo = replyTo.map(mapFn).filter((v) => !!v);

  return { to, cc, bcc, replyTo };
}

/**
 * Get the export file name for a given form data.
 * @param formData The form data to get the filename for.
 * @returns THe export file name.
 */
export function getExportFilename (formData: FormData): string {
  let name: string;
  if (formData.exportNameTemplate) {
    // parse template
    name = replaceTemplateVarFieldValues(formData.exportNameTemplate, formData);
  } else {
    // get the export name from the sequential number or the form id
    name = getFormSequentialNumber(formData);
    if (!name) {
      name = formData.id;
    }
    name = `form_${name}`;
  }

  // replace chars
  return name.toLowerCase().replace(/[^a-z0-9-_]/g, '_');
}
