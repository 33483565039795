import * as Sentry from '@sentry/browser';
import type { Integration } from '@sentry/types';

import 'w3-css';
import './w3-theme.css';
import '@fontsource/roboto';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.css';
import './print.css';

import { FormEdit } from './formedit';
import { FormsList } from './forms-list';
import { resizeTextarea } from './browser-helpers';

// init sentry if enabled
if (sentryCfg) {
  const opts: Sentry.BrowserOptions = {
    dsn: sentryCfg.dsn,
    release: sentryCfg.release,
    tracesSampleRate: sentryCfg.tracesSampleRate,

    integrations: [
      Sentry.replayIntegration(),
    ],

    // capture replay for 100 % of sessions with an error
    replaysOnErrorSampleRate: 1,
  };

  if (sentryCfg.perfData) {
    (opts.integrations as Integration[]).push(Sentry.browserTracingIntegration());

    // capture replay for 10 % of all sessions
    opts.replaysSessionSampleRate = 0.1;
  }

  Sentry.init(opts);

  if (userData) {
    Sentry.setUser({
      id: userData.id,
      info: userData,
    });
  }
}

/*
 * Adjust height of textarea elements according to their content
 */
const textareas = document.querySelectorAll<HTMLTextAreaElement>('textarea.autoheight');

/**
 * Resize height of textarea elements automatically according to their content,
 * but not higher than 380px.
 */
textareas.forEach((ta) => {
  resizeTextarea(ta);
  ta.addEventListener('input', () => resizeTextarea(ta));
});

/**
 * Adjust the height of the main element to fit on screen with the top bar.
 */
function adjustMainHeight (): void {
  setTimeout(() => {
    const main = document.getElementById('main');
    const topbar = document.getElementById('topbar');
    if (!main || !topbar) return;
    main.style.top = `${topbar.offsetHeight}px`;
  }, 10);
}

adjustMainHeight();
window.addEventListener('resize', adjustMainHeight);

if (userData && formData) {
  new FormEdit(formData);
}

if (document.getElementById('list-filter')) {
  new FormsList();
}
