export function resizeTextarea (ta: HTMLTextAreaElement): void {
  ta.style.height = `1px`;
  ta.style.height = `${ta.scrollHeight + 1}px`;
}

export function findFormfieldRootElement (elem: string | HTMLElement): HTMLElement | null {
  let elem2: HTMLElement | null;
  if (typeof elem === 'string') {
    elem2 = document.getElementById(elem);
  } else {
    elem2 = elem;
  }

  if (!elem2) return null;

  while (!elem2.classList.contains('formfield-root')) {
    elem2 = elem2.parentElement;
    if (!elem2) return null;
  }

  return elem2;
}
