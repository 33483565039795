/**
 * Class to handle forms list specific stuff.
 */
export class FormsList {

  /**
   * Element of the filter input.
   */
  private filterInput: HTMLInputElement;

  /**
   * Element of the filter label.
   */
  private filterLabel: HTMLLabelElement;

  /**
   * Element of the filter clear button.
   */
  private filterClearButton: HTMLButtonElement;

  /**
   * Element which contains the "no forms" information.
   */
  private noForms: HTMLElement;

  /**
   * Mapping of elements which may be filtered to their values which should be
   * checked during filtering.
   */
  private filterElements: Map<HTMLElement, string[]> = new Map<HTMLElement, string[]>();

  constructor () {
    this.filterInput = document.getElementById('list-filter') as HTMLInputElement;
    this.filterLabel = document.getElementById('list-filter-label') as HTMLLabelElement;
    this.noForms = document.getElementById('list-no-forms') as HTMLElement;
    this.filterClearButton = document.getElementById('list-filter-clear-button') as HTMLButtonElement;

    // create mapping for filtering
    const elems = document.querySelectorAll<HTMLElement>('[data-filter-content]');
    elems.forEach((elem) => {
      if (!elem.dataset.filterContent) return;
      try {
        const json = JSON.parse(elem.dataset.filterContent) as string[];
        if (!Array.isArray(json)) {
          throw new Error('JSON data is not an array');
        }
        this.filterElements.set(elem, json.map((v) => v.normalize('NFKC').toLowerCase().trim()));
      } catch (err) {
        console.error('Error parsing dataset filter-content!');
        console.error(err);
      }
    });

    // handle filter input changes
    this.filterInput.addEventListener('change', () => this.handleFilterInputChange());
    this.filterInput.addEventListener('input', () => this.handleFilterInputChange());

    // handle filter clear button clicks
    this.filterClearButton.addEventListener('click', () => {
      this.filterInput.value = '';
      this.handleFilterInputChange();
    });

    // handle filter label clicks (apply the filter)
    this.filterLabel.addEventListener('click', () => this.handleFilterInputChange());

    // fill in query string from url parameters to filter input
    const searchParams = new URLSearchParams(window.location.search);
    const q = searchParams.get('q');
    if (q) {
      this.filterInput.value = q;
      this.handleFilterInputChange();
    }

    // show the filter container
    document.getElementById('list-filter-container')?.classList.remove('w3-hide');
  }

  /**
   * Handler for changes of the filter input.
   *
   * This will check the elements to filter and show/hide them accordingly.
   */
  private handleFilterInputChange (): void {
    const filterValue = this.filterInput.value.normalize('NFKC').toLowerCase().trim();

    if (filterValue) {
      this.filterClearButton.classList.remove('w3-hide');
    } else {
      this.filterClearButton.classList.add('w3-hide');
    }

    let shown = 0;

    for (const [ elem, contents ] of this.filterElements) {
      if (!filterValue) {
        elem.classList.remove('filter-hide');
        shown++;
        continue;
      }

      let hide = true;
      for (const content of contents) {
        if (typeof content !== 'string') continue;
        if (content.includes(filterValue)) {
          hide = false;
          break;
        }
      }

      if (hide) {
        elem.classList.add('filter-hide');
      } else {
        elem.classList.remove('filter-hide');
        shown++;
      }
    }

    // show/hide no forms info
    if (shown > 0) {
      this.noForms.classList.add('w3-hide');
    } else {
      this.noForms.classList.remove('w3-hide');
    }

  }
}
